.lead-header {
  display: flex !important ;

  .filter-sort {
    width: 50%;
    // margin-right: 87px;

    span {
      font-size: 12px !important;
    }
  }
}

.filter-sort {
  display: flex;
  width: 50%;
  height: 50px;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    width: 33.33%;

    font-size: 16px;
    font-weight: 500;
    color: $black;
    letter-spacing: 1px;
    text-transform: uppercase;

    background: transparent;

    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: $white;
      background-color: $gray150;

      cursor: pointer;
      transition: all 0.2s ease-in-out;
    }

    &.active {
      color: $white;

      background-color: $link-color;
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .lead-header {
    display: block !important;
  }

  .detail-title {
    .left-right {
      width: 45px;
    }
  }

  .filter-wrapper {
    margin-bottom: 15px;
  }

  .date-range-parent.custom-range-parent {
    margin-left: 0;

    .ui.selection.dropdown {
      min-width: 100%;
    }
  }

  .lead-header {
    .filter-sort {
      width: 100% !important;

      span {
        font-size: 12px !important;
      }
    }
  }

  .detail-popup-profile {
    .detailHeadProfile {
      .popUpDetail {
        .callDetail {
          p.email-text {
            word-break: break-all;
          }
        }
      }
    }
  }

  .aboutTab {
    .popUpDetail {
      .callDetail {
        .titleAbout {
          word-break: break-all;

          p {
            word-break: break-all;
          }
        }
      }
    }
  }
}

.holder-filter-leads {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  margin: 20px 0;

  background: #f9fafb;

  .holder-filter-leads .filter-sort span.active {
    background-color: #d3d3d3 !important;
  }

  i.circle.left {
    position: absolute;
    top: 18px;
    left: -15px;
    z-index: 1;

    font-size: 25px;

    cursor: pointer;

    &:before {
      color: #636464;

      background: #e0e1e2 !important;
      border-radius: 50%;
      box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
    }
  }

  i.circle.up {
    position: absolute;
    top: 18px;
    left: -15px;
    z-index: 1;

    font-size: 25px;

    cursor: pointer;

    &:before {
      color: #636464;

      background: #e0e1e2 !important;
      border-radius: 50%;
      box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
    }
  }

  .filter-lead {
    width: 50%;
  }

  .filter-sort {
    display: flex;
    width: 50%;
    height: 50px;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 0;
      width: 33.33%;

      font-size: 16px;
      font-weight: 500;
      color: $black;
      letter-spacing: 1px;
      text-transform: uppercase;

      background: transparent;

      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: $white;
        background-color: $gray150;

        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }

      &.active {
        color: $white;

        background-color: $link-color;
      }
    }
  }

  .filter-holder {
    display: flex;
    align-items: center;
    margin-right: 10px;
    // flex-wrap: wrap;

    .filter-condition {
      width: auto;

      .ui.labeled.icon.button {
        margin-right: 15px;
        width: auto;

        font-size: 13px;
        text-align: left;
        text-transform: lowercase;
      }

      .ui.input {
        input {
          font-size: 13px;
        }
      }
    }

    .add-filter {
      width: auto;
    }
  }

  .filter-add {
    width: 100%;

    .btn-hours {
      background: none;
    }
  }

  .filter-tab {
    display: flex;
    margin-bottom: 10px;

    .filter-holder {
      width: auto;

      .ui.labeled.icon.button {
        width: auto;
      }
    }

    .btn-delete {
      padding: 0;

      background: none;
    }
  }

  .filter-list {
    width: 15%;

    .ui.labeled.icon.button {
      width: 100%;
    }
  }

  .filter-leads-holder {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
  }

  .ui.labeled.icon.button {
    margin-right: 15px;
    width: auto;

    font-size: 13px;
    text-align: left;
    text-transform: capitalize;
  }

  .ui.input {
    input {
      padding: 8px;
    }
  }
}

@media screen and (max-width: 1440px) and (min-width: 1200px) {
  .holder-filter-leads {
    .filter-lead {
      width: 100% !important;
    }
  }
}

@include respond-to(lg-down) {
  .holder-filter-leads {
    .filter-lead {
      width: 100% !important;
    }

    .filter-sort {
      margin: 30px 0;
      width: 100% !important;
    }
  }
}

@include respond-to(md-down) {
  .holder-content {
    .holder-filter-leads {
      i.circle.up {
        top: -15px;
        left: 45%;
      }

      .leads-tab {
        width: 100% !important;

        .four {
          padding: 75px 0;
          width: 25% !important;
        }

        .stretched {
          padding: 0;
          width: 75% !important;
        }
      }

      .holder-content {
        width: 100% !important;
        padding: 10px !important;

        .holder-table {
          .table {
            width: 100% !important;

            .chart-filter {
              .heading {
                flex-wrap: wrap;

                .holder-btn-export {
                  width: 100%;

                  button {
                    margin-bottom: 10px;
                    width: 100%;
                  }
                }

                .filter {
                  flex-wrap: wrap;
                  width: 100%;

                  span {
                    width: 100%;
                  }
                }
              }
            }

            .table-content {
              .holder-pagination {
                flex-wrap: wrap;
                padding-top: 20px;

                .pagination {
                  padding: 0 10px;
                  width: 100%;

                  .table-delete {
                    flex-wrap: wrap;
                    justify-content: center;
                    padding: 0;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.meet-title-holder {
  padding: 15px 30px 15px 122px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1199px) and (min-width: 992px) {
    padding: 15px 30px 15px 90px;
  }

  .metting-filter-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .ui.checkbox {
      margin-right: 15px;
      label {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #171b26;
        margin: 0;
      }

      label:before {
        border: 1px solid #e6e8ee;
        border-radius: 2px;
        width: 20px;
        height: 20px;
      }

      label:after {
        top: 2px;
        left: 4px;
      }
    }

    .date-selct-metting {
      input {
        border: 1px solid #e6e8ee;
        padding: 6px 9px;
        border-radius: 4px;
        margin-left: 14px;
        margin-right: 14px;
      }
    }

    .filter-metting {
      position: relative;

      .selection.dropdown {
        padding: 0.78571429em 2.1em 0.78571429em 41px;
        border: 1px solid #e6e8ee;
        border-radius: 4px;

        .divider.default.text {
          color: #000 !important;
        }

        .transition {
          animation-fill-mode: initial;
        }
      }

      i {
        display: none;
      }

      img {
        position: absolute;
        top: 50%;
        left: 7px;
        transform: translateY(-50%);
      }
    }
  }

  .schedule_meeting {
    display: flex;
    align-items: center;

    @media (min-width: 320px) and (max-width: 1199px) {
      display: block;
    }

    button {
      border: 1px solid #0071eb;
      border-radius: 4px;
      background: #fff;
      padding: 9px 13px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #0071eb;
      cursor: pointer;
      margin-right: 20px;

      @media (min-width: 320px) and (max-width: 1199px) {
        margin-right: 7px;
        padding: 9px 5px;
        margin-bottom: 10px;
      }

      img {
        padding-left: 8px;
      }
    }
  }

  .meeting-time {
    button {
      border: 1px solid #0071eb;
      border-radius: 4px;
      background: #0071eb;
      padding: 9px 13px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
    }
  }
}

.metting-event {
  padding: 15px 30px 15px 65px;

  .ui.pointing.secondary.menu {
    border-bottom: 1px solid #e6e8ee;
    margin-bottom: 0;
  }

  .ui.segment {
    box-shadow: none;
    border: none;
    margin-top: 0;
    padding-top: 0;

    table.ui.single {
      border: 0;

      thead {
        tr {
          th {
            color: #a4abc5;
            font-size: 16px;
            font-weight: 400;
            background: #fff;
            border-bottom: 0;
          }
        }

        // @media(max-width:767px) {
        //     display: contents;
        // }
      }

      tbody {
        // tr,
        // td {
        //     @media(max-width:767px) {
        //         display: table-row !important;
        //     }
        // }

        tr:hover {
          td {
            background-color: #effafc;
          }

          .social-box-wrapper {
            opacity: 1 !important;
          }
        }

        tr:nth-child(3n) td {
          border-bottom: 1px solid #e6e8ee;
        }

        tr:hover td:nth-child(1) {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        tr:hover td:last-child(1) {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        tr {
          td {
            border-top: 0;
          }

          td.date-set-table {
            color: #171b26;
            font-weight: 500;
          }

          td.time-set-table {
            font-size: 14px;
            color: #171b26;
          }

          td.metting-name {
            color: #171b26;
            font-size: 14px;
            font-weight: 500;

            p {
              margin: 0;
            }
          }

          td.metting-name p:nth-child(2) {
            color: #a4abc4;
            margin: 0;
            font-size: 14px;
          }

          td.link-table {
            color: #a4abc4;
            font-weight: 500;
            font-size: 16px;

            img {
              margin-left: 5px;
            }
          }

          td.host-table {
            .host-profile {
              display: flex;
              align-items: center;

              img.circular.image {
                width: 30px !important;
              }

              p {
                color: #171b26;
                font-weight: 500;
                font-size: 16px;
                margin-left: 10px;
              }
            }
          }

          td.location-table {
            .location-img {
              display: flex;
              align-items: center;

              img.circular.image {
                width: 30px !important;
              }

              p {
                color: #171b26;
                font-weight: 500;
                font-size: 16px;
                margin-left: 10px;
              }
            }
          }

          .social-icon-set {
            .social-box-wrapper {
              display: flex;
              align-items: center;
              opacity: 0;
              transition: all 0.3s;

              .social-boxes {
                width: 40px;
                height: 40px;
                border-radius: 50px;
                background: #0071eb;
                justify-content: center;
                align-items: center;
                display: flex;
                margin: 0px 3px;

                img {
                  width: 20px !important;
                }
              }
            }
          }
        }

        // @media(max-width:767px) {
        //     display: table-row !important;
        // }
      }
    }
  }

  .secondary.menu {
    .active.item {
      border-color: #0071eb !important;
    }

    .item {
      color: #a4abc5;
      font-size: 16px;
      width: 19%;
      min-width: 200px;
      justify-content: center;
    }
  }
}

.details-popup.active {
  transform: translateX(0%);
}

.details-popup {
  position: fixed;
  top: 0;
  right: 0;
  width: 390px;
  z-index: 9999;
  background: #fff;
  height: 100%;
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
  transition: all 0.5s;

  @media (max-width: 425px) and (min-width: 320px) {
    width: 310px;
  }

  .details-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 20px;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
    img {
      cursor: pointer;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      color: #262b3f;
      margin: 0;
    }
  }

  .details-popup-profile {
    padding: 17px 20px;

    .details-title-icon {
      font-size: 20px;
    }

    .calenderIcon {
      margin: 10px 0px;

      label {
        margin-left: 10px;
      }
    }

    .timeIcon {
      label {
        margin-left: 10px;
      }
    }

    img {
      margin-left: 15px;
    }

    .details-popup-profile-detail {
      padding-left: 14px;

      @media (max-width: 425px) and (min-width: 320px) {
        padding-left: 12px;
      }

      .details-popup-title {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        color: #262b3f;
        margin-bottom: 0;
      }

      .employee-title {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #a4abc4;
        margin: 5px 0px;
      }

      .details-number {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #171b26;

        img {
          padding-right: 8px;
        }
      }

      .social-box-wrapper {
        display: flex;
        align-items: center;
        transition: all 0.3s;
        margin-top: 10px;

        .social-boxes {
          width: 48px;
          height: 48px;
          border-radius: 50px;
          border: 2px solid #e6e8ee;
          justify-content: center;
          align-items: center;
          display: flex;
          margin: 0px 5px;

          img {
            width: 20px !important;
            margin-left: 0 !important;
          }
        }
      }
    }
  }

  .contact-popup-detail {
    a {
      width: 33.33%;
      display: flex;
      justify-content: center;
      color: #a4abc5 !important;
    }

    a.active {
      color: #000 !important;
      border-bottom: 2px solid #0071eb !important;
    }
  }
}

.contact-card {
  background: #f5f5f5;
  padding-top: 1px;
  margin-top: -15px;
  overflow-y: scroll;
  height: 500px;

  @media (max-width: 1600px) and (min-width: 320px) {
    height: 56vh;
  }

  .contact-card-boxess {
    .row {
      margin: none !important;
      padding: none !important;
    }
  }
  .contact-card-boxes {
    background: #ffffff;
    border-radius: 8px;
    margin: 20px;
    padding: 15px;

    .row {
      margin: 0;
      padding: 15px 15px 15px 15px;

      .wide.column {
        padding: 0;
      }

      p.card-boxes-right-text {
        color: #171b26;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
      }

      p.card-boxes-left-text {
        color: #a4abc4;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
      }
    }

    .column {
      padding: 10px 10px 0px !important;

      .host-profile {
        display: flex;
        align-items: center;

        img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }

        p {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    p.card-boxes-title {
      font-style: normal;
      font-size: 14px;
      color: #171b26;
      font-weight: 600;
      margin: 0;
      text-transform: uppercase;
    }
  }

  .guestWrap {
    .host-profile {
      display: flex;
      align-items: center;
      padding-left: 12px;
      margin: 10px 0px;

      p {
        margin-left: 10px;
      }

      img {
        width: 24px !important;
      }
    }

    .flagmailWrap {
      padding-left: 50px;
      font-size: 14px;

      .flagWrap {
        display: flex;
        align-items: center;
        margin: 10px 0px;

        p {
          margin-left: 10px;
        }
      }

      .mailWrap {
        display: flex;
        align-items: center;

        p {
          margin-left: 10px;
        }
      }

      img {
        width: 16px !important;
      }
    }
  }

  .locationWraper {
    .locationMain {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;

      .zoomWrap {
        display: flex;
        align-items: center;

        p {
          margin-left: 10px;
        }

        img {
          width: 24px !important;
        }
      }

      .linkWrap {
        display: flex;
        align-items: center;

        p {
          margin-bottom: 0px;
          margin-right: 10px;
          color: #a4abc4 !important;
        }

        img {
          width: 16px !important;
        }
      }
    }
  }

  .sendingmeetWraper {
    .sendmeetingWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;

      .senddropdown {
        display: flex;
        align-items: center;
        border: 1px solid #dbdee8;
        border-radius: 4px;
        padding: 5px 10px;
        width: 200px;
        position: relative;

        @media (max-width: 375px) {
          width: 160px;
        }

        .dropdown {
          position: relative;
          left: 22px;

          .text {
            margin-right: 22px;

            @media (max-width: 375px) {
              margin-right: 0px;
            }
          }
        }

        img {
          // margin-right: 10px;
        }

        i {
          position: absolute;
          right: -84%;
          top: 50%;
          transform: translate(50%, -5%);
        }
      }

      .sendswitch {
        .ui.toggle.checkbox input:focus:checked ~ .box:before,
        .ui.toggle.checkbox input:focus:checked ~ label:before {
          background-color: #0071eb !important;
        }

        .ui.toggle.checkbox input:checked ~ .box:before,
        .ui.toggle.checkbox input:checked ~ label:before {
          background-color: #0071eb !important;
        }
      }

      img {
        width: 17px !important;
        height: 19px !important;
        margin-left: 0px !important;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .meet-title-holder {
    .ui.segment {
    }
  }

  .metting-event {
    .secondary.menu {
      .item {
        width: 20%;
      }
    }

    .ui.segment {
      overflow-x: auto;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .meet-holder {
    .meet-title-holder {
      flex-flow: column;
      align-items: flex-start;

      .metting-filter-section {
        margin-top: 22px;
      }
    }
  }

  .metting-event {
    .secondary.menu {
      .item {
        width: 22%;
      }
    }

    .ui.segment {
      overflow-x: auto;
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .meet-holder {
    .meet-title-holder {
      padding: 15px 30px 15px 75px;
      flex-flow: column;
      align-items: flex-start;

      .metting-filter-section {
        margin-top: 10px;
        flex-flow: column;
        align-items: flex-start;

        .date-selct-metting {
          input {
            margin: 14px 0px;
          }
        }
      }
    }
  }

  .metting-event {
    .secondary.menu {
      .item {
        width: 32%;
      }
    }

    .ui.segment {
      overflow-x: auto;
    }
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .meet-holder {
    .meet-title-holder {
      padding: 15px 30px 15px 80px !important;
    }
  }
}

.sidebarWrap {
    width: calc(100% - $nav-width);
      float: right;
  display: flex;
  margin-left: 64px;

  .meetingSidebar {
    position: fixed;
    width: 178px;
    height: 100%;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);

    .sideHeader {
      padding: 20px;
      border-bottom: 1px solid #e6e8ee;
      margin-bottom: 15px;
      margin-top: 15px;

      h1 {
        font-size: 20px;
        font-weight: 600;
      }
    }

    label {
      width: 100%;
      padding-left: 40px;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 20px;
    }

    .sideTab {
      color: #0071eb;
      font-weight: 500;
    }
  }

  .myleadSidebar {
    position: fixed;
    width: 220px;
    height: 100%;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
    background-color: #282828;
    color: #fff !important;

    label {
      color: #fff !important;
    }

    .small-text {
      font-size: 10px;
      margin-top: 3px;
    }

    .sideHeader {
      padding: 13px 0px;

      h1 {
        font-size: 20px;
        font-weight: 600;
      }
    }

    label {
      width: 100%;
      padding-left: 40px;
      font-size: 14px;
      font-weight: 400;
    }

    .sideTab {
      color: #0071eb;
      font-weight: 500;
    }
  }

  .meetWrapper {
    margin-top: 80px;
    margin-left: 120px;
    width: 100%;

    .meet-container {
      .title-container.header_fixed {
        left: $nav-width;
        box-shadow: none;
        border-bottom: 1px solid #e6e8ee;
        padding: 15px 30px 10px 30px;
        border-left: 1px solid #e6e8ee;
      }
    }
  }

  .myleadWrapper {
    margin-top: 80px;
    margin-left: 180px;
    width: 100%;

    .meet-container {
      .title-container.header_fixed {
        left: $nav-width;
        box-shadow: none;
        border-bottom: 1px solid #e6e8ee;
        padding: 15px 30px 10px 30px;
        border-left: 1px solid #e6e8ee;
      }
    }
  }
}

.scheduleHeadDetail {
  padding: 20px;

  .schedulename {
    display: block;

    label {
      width: 100%;
      font-size: 14px;
      color: #a4abc4;
      font-weight: 400;
    }

    .input {
      width: 100%;
      height: 35px;

      input::placeholder {
        color: #000 !important;
      }
    }
  }

  .scheduledate {
    display: block;

    label {
      width: 100%;
      font-size: 14px;
      color: #a4abc4;
      font-weight: 400;
      margin-top: 10px;
    }

    .react-datepicker-wrapper {
      width: 100%;

      input {
        width: 100%;
        height: 35px;
        border: 1px solid rgba(34, 36, 38, 0.15);
        padding: 0px 10px;

        ::placeholder {
          color: #000 !important;
        }
      }
    }
  }

  .fromtowrap {
    display: flex;

    .fromWrap {
      width: 50%;

      .fromdropdown {
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-radius: 4px;
        padding: 3px 10px;
        width: 95%;
        position: relative;

        i {
          position: absolute;
          right: 0;
          top: 0;
          transform: translate(-7px, 8px);
        }
      }

      label {
        width: 100%;
        font-size: 14px;
        color: #a4abc4;
        font-weight: 400;
        margin-top: 10px;
      }
    }

    .toWrap {
      width: 50%;

      .fromdropdown {
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-radius: 4px;
        padding: 3px 10px;
        width: 95%;
        position: relative;

        i {
          position: absolute;
          right: 0;
          top: 0;
          transform: translate(-7px, 8px);
        }
      }

      label {
        width: 100%;
        font-size: 14px;
        color: #a4abc4;
        font-weight: 400;
        margin-top: 10px;
      }
    }
  }
}

.mettingSubMain {
  overflow-y: scroll;
  height: 100vh;
  max-height: 750px;
  // max-height: 730px;
  // height: 730px;
  // overflow-y: scroll;
  // padding-bottom: 100px;

  @media (max-width: 991px) and (min-width: 320px) {
    max-height: 640px;
  }

  @media (max-width: 1200px) and (min-width: 992px) {
    max-height: 640px;
  }

  .scheduleHeadDetail {
    padding: 20px;

    .schedulename {
      display: block;

      label {
        width: 100%;
        font-size: 14px;
        color: #a4abc4;
        font-weight: 400;
      }

      .input {
        width: 100%;
        height: 35px;

        input::placeholder {
          color: #000 !important;
        }
      }
    }

    .scheduledate {
      display: block;

      label {
        width: 100%;
        font-size: 14px;
        color: #a4abc4;
        font-weight: 400;
        margin-top: 10px;
      }

      .react-datepicker-wrapper {
        width: 100%;

        input {
          width: 100%;
          height: 35px;
          border: 1px solid rgba(34, 36, 38, 0.15);
          padding: 0px 10px;

          ::placeholder {
            color: #000 !important;
          }
        }
      }
    }

    .fromtowrap {
      display: flex;

      .fromWrap {
        width: 50%;

        .fromdropdown {
          border: 1px solid rgba(34, 36, 38, 0.15);
          border-radius: 4px;
          padding: 3px 10px;
          width: 95%;
          position: relative;

          i {
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(-7px, 8px);
          }
        }

        label {
          width: 100%;
          font-size: 14px;
          color: #a4abc4;
          font-weight: 400;
          margin-top: 10px;
        }
      }

      .toWrap {
        width: 50%;

        .fromdropdown {
          border: 1px solid rgba(34, 36, 38, 0.15);
          border-radius: 4px;
          padding: 3px 10px;
          width: 95%;
          position: relative;

          i {
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(-7px, 8px);
          }
        }

        label {
          width: 100%;
          font-size: 14px;
          color: #a4abc4;
          font-weight: 400;
          margin-top: 10px;
        }
      }
    }
  }
}

.contact-popup-detail {
  .secondary.menu a.item{
    width: auto;
    min-width: 0;
  }
  .senddropdown {
    display: flex;
    align-items: center;
    position: relative;

    img {
      width: 30px !important;
      position: absolute;
      margin-left: 10px;
    }

    .hostdropdown {
      position: relative;
      height: 40px;
      border: 1px solid rgba(34, 36, 38, 0.15);
      width: 100%;
      padding: 5px 10px 5px 15px;
      border-radius: 5px;
      display: flex;
      align-items: center;

      .text {
        margin-left: 36px;

        img {
          margin-left: -41px !important;
        }
      }

      i {
        position: absolute !important;
        right: 0;
        top: 0;
        transform: translate(0px, 2px) !important;
      }

      .menu {
        .item {
          img {
            margin-left: 10px;
            margin-top: 4px;
          }

          span {
            margin-left: 36px;
          }
        }
      }
    }
  }

  .addGuest {
    display: flex;
    align-items: center;
    margin-top: 10px;

    img {
      width: 20px !important;
    }

    label {
      margin-bottom: 0;
      margin-left: 10px;
      color: #0071eb;
    }
  }
}

.schedulePopup {
  height: 400px;
  padding-bottom: 300px !important;

  @media (min-width: 320px) and (max-width: 1499px) {
    height: 300px;
  }

  .schedulebellWrap {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin-top: 10px;
    padding: 0px 10px;

    .scheduletabdropdown {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      border: 1px solid #dbdee8;
      border-radius: 4px;
      padding: 5px 7px;
      width: 200px;
      position: relative;

      @media (max-width: 425px) {
        width: 150px;
      }

      img {
        margin-right: 10px;
        position: absolute;
        width: 20px;
      }

      .dropdown {
        padding-left: 33px;

        i {
          position: absolute;
          right: -10%;
          top: 50%;
          /* -webkit-transform: translate(50%, -50%); */
          transform: translate(50%, -50%);

          @media (max-width: 425px) {
            right: -3%;
          }
        }
      }

      .menu {
        width: 200px !important;
        left: -7px;
        top: 27px !important;

        span.text {
          padding-left: 35px;
        }
      }
    }

    .scheduleswitch {
      margin-top: 10px;

      .ui.toggle.checkbox input:focus:checked ~ .box:before,
      .ui.toggle.checkbox input:focus:checked ~ label:before {
        background-color: #0071eb !important;
      }

      .ui.toggle.checkbox input:checked ~ .box:before,
      .ui.toggle.checkbox input:checked ~ label:before {
        background-color: #0071eb !important;
      }
    }
  }

  .scheduleButton {
    margin-top: 70px;
    padding: 10px;
    display: flex;
    background: #fff;

    button {
      width: 50%;
    }

    .btnCancle {
      color: #171b26;
      background: #fff;
      border: 1px solid #e6e8ee;
    }

    .btnSave {
      background: #0071eb;
      color: #fff;
    }
  }
}

@media only screen and (max-width: 412px) {
  .ui.modal {
    width: 95% !important;
    margin: 0;
  }
}

//reschedule popup
.Reschedule-popup {
  overflow: visible !important;
}

.rescheduleWrap {
  width: 390px !important;
  will-change: initial !important;
  animation-fill-mode: initial !important;

  .rescheduleheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e6e8ee;

    p {
      margin-bottom: 0;
      font-size: 24px;
      font-weight: 400;
    }

    img {
      width: 17px;
    }
  }

  .labelHostname {
    font-size: 20px;
    font-weight: 400;
    padding: 0px 20px;
    margin-bottom: 0;
  }

  .scheduleHeadDetail {
    padding: 10px 20px;

    .fromdropdown {
      padding: 6px 10px !important;
    }

    label {
      margin-left: 7px;
    }

    .todropdown {
      margin-left: 5px;
    }
  }

  .scheduleButton {
    margin-top: 10px;
    padding: 17px;
    display: flex;
    background: #fff;
    border-top: 1px solid #e6e8ee;

    button {
      width: 47%;
    }

    .btnCancle {
      color: #171b26;
      background: #fff;
      border: 1px solid #e6e8ee;
    }

    .btnSave {
      background: #0071eb;
      color: #fff;

      margin-left: 10px;
    }
  }
}

//cancel popup
.cancelmainWrap {
  width: 390px !important;

  .cancelheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e6e8ee;

    p {
      margin-bottom: 0;
      font-size: 24px;
      font-weight: 400;
    }

    img {
      width: 17px;
    }
  }

  .cancelButton {
    margin-top: 10px;
    padding: 17px;
    display: flex;
    background: #fff;
    border-top: 1px solid #e6e8ee;

    button {
      width: 47%;
      text-transform: capitalize;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0px;

      @media (max-width: 375px) {
        font-size: 12px;
      }
    }

    .btnpopupCancle {
      color: #171b26;
      background: #fff;
      border: 1px solid #e6e8ee;
    }

    .btnpopupSave {
      background: #ef6e6e;
      color: #fff;

      margin-left: 10px;
    }
  }

  .cancelparagraph {
    padding: 10px 20px;

    p {
      font-size: 20px;
    }
  }
}

//create meeting pop up
.scheduleHeadDetail {
  padding: 20px 20px 10px 20px;

  .btnduration {
    button {
      width: 19%;
      font-size: 14px !important;
      text-transform: lowercase !important;
      padding: 9px 5px !important;
      background: #fff !important;
      border: 1px solid rgba(34, 36, 38, 0.15) !important;
      color: #171b26 !important;
      font-weight: 400 !important;

      &:focus,
      &:hover {
        background: #0071eb !important;
        color: #fff !important;
      }
    }
  }
}

.memberMainWrap {
  background: #f5f5f5;
  padding: 20px 0px 0px 0px;
  overflow: auto;
  height: 270px;
  padding-bottom: 30px;

  .guestWrap {
    background: #fff;
    border-radius: 8px;
    margin: 0px 15px 20px 15px;
    padding: 15px;

    p {
      color: #171b26;
      font-weight: 500;
    }

    .addGuest {
      display: flex;
      align-items: center;
      margin-top: 10px;

      img {
        width: 20px !important;
      }

      label {
        margin-bottom: 0;
        margin-left: 10px;
        color: #0071eb;
      }
    }

    .senddropdown {
      color: red;

      .selection {
        .text {
          margin-left: 36px;

          img {
            margin-left: -41px !important;
          }
        }

        .menu {
          .item {
            display: flex;

            img {
              margin-left: 10px;
              margin-top: 4px;
            }

            span {
              margin-left: -15px;
            }
          }
        }
      }
    }
  }

  .createdropdown {
    .selection {
      .text {
        margin-left: 36px;

        img {
          margin-left: -41px !important;
        }
      }

      .menu {
        .item {
          display: flex;

          img {
            margin-left: 10px;
            margin-top: 4px;
          }

          span {
            margin-left: -15px;
          }
        }
      }
    }
  }
}

.scheduleButton {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 15px 10px;
  display: flex;
  background: #fff;
  z-index: 999;

  button {
    width: 50%;
  }

  .btnCancle {
    color: #171b26;
    background: #fff;
    border: 1px solid #e6e8ee;
  }

  .btnSave {
    background: #0071eb;
    color: #fff;
  }
}

//mobile menu
.mobilemenu {
  justify-content: initial;

  .welcome-name {
    padding-left: 15px;
  }

  img {
    width: 20px !important;
    display: none;

    @media (max-width: 991px) {
      display: block !important;
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .memberMainWrap {
    height: 33vh;
  }
}

.searchHead {
  position: relative;
  right: 38%;

  @media (max-width: 1299px) and (min-width: 992px) {
    right: 50%;

    .meetingHead {
      display: none;
    }
  }
}

@media (max-width: 1299px) and (min-width: 992px) {
  .meetingHead {
    padding-left: 0px !important;
  }
}

@media (max-width: 991px) and (min-width: 320px) {
  .searchHead {
    right: 0%;
    margin-top: -20px;
  }

  .meetingHead {
    display: none !important;
  }
}

@media (max-width: 991px) and (min-width: 320px) {
  .headerMeeting {
    padding: 0px !important;
    border-bottom: 0 !important;
  }
}

.ui.selection.dropdown {
  // padding: 17px 15px;
  width: 100%;
height: auto;
  background: url(../assets/images/arrow-down-dark.png) no-repeat 94% 50%;
  background-color: $white;
  background-size: 16px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    border: 1px solid #286efa;
  }

  .menu {
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
  }

  .text {
    font-size: 16px;
    font-weight: 300;
    color: $black;
    line-height: normal;

    cursor: pointer;
  }

  span.text {
    padding: 10px 15px;

    @include on-event {
      background-color: $hover-row;
    }
  }

  i {
    display: none;
  }
}

.btn-close {
  padding: 10px 20px;
  font-family: $code-font-stack;
  font-weight: 300;
  color: #fff;
  text-transform: capitalize;
  background: $link-color;
  border: 1px solid $link-color;
  letter-spacing: 1px;
  font-size: 14px;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $link-color;

    background: transparent;
  }
}

.hover-holder:hover {
  background-color: #323232 !important;
  .icons {
    visibility: visible !important;
  }
}

.active-tag {
  background-color: #2185d0 !important;
}

.ui.segment {
  overflow: scroll;
}

.content-wrap {
  margin: 0 auto;
  max-width: 1080px;
  padding-bottom: 30px;
  @media screen and (min-width: 992px) {
    padding-left: 80px;
  }

  a {
    color: #fff;
    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }
  .credit-box {
    padding: 30px;
    background-color: #333;
    color: #fff;
    display: block;
    @media screen and (max-width: 1199px) {
      padding: 20px;
    }
    @media screen and (max-width: 991px) {
      display: flex;
      align-items: center;
      color: #333;
      justify-content: flex-end;
      padding: 15px;
      background-color: #f1f1f2;
    }
    h3 {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0;
      @media screen and (min-width: 992px) {
        font-size: 40px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column-reverse;
      }
      span {
        font-size: 14px;
        @media screen and (min-width: 992px) {
          display: block;
          margin-bottom: 10px;
        }
        @media screen and (max-width: 991px) {
          margin-right: 10px;
          margin-left: 4px;
          font-weight: bold;
        }
      }
    }
    .ui {
      background-color: #1bd2b0;
      color: #fff;
      letter-spacing: 2px;
      @media screen and (min-width: 992px) {
        padding: 18px 15px;
        font-size: 20px !important;
        width: 100%;
      }
    }
  }
  .link-box {
    background-color: #f1f1f2;
    padding: 30px;
    @media screen and (max-width: 991px) {
      display: none;
    }
    h3 {
      font-weight: bold;
      &:not(:first-child) {
        margin-top: 15px;
      }
    }
    a {
      color: #0071eb;
      font-size: 18px;
      margin-top: 10px;
      display: block;
      text-decoration: none;
    }
  }
}
.send-message-form {
  .fields {
    margin-bottom: 10px;
    label {
      display: block;
      font-size: 18px;
    }
    b {
      font-weight: 600;
    }
    .ui.selection.dropdown {
      box-shadow: none;
    }
    .ui {
      &.secondary {
        background-color: #6d6d6d;
        padding: 15px 30px;
        font-size: 20px !important;
        letter-spacing: 2px;
      }
    }
  }
  .ui {
    .label {
      font-weight: 600;
    }
    input {
      flex: 1;
    }
  }
}
.msgOpts {
  max-width: 120px;
  margin: 0;
  padding: 0;
  li {
    display: block;
    background-color: #0071eb;
    color: #fff;
    padding: 6px 30px 6px 10px;
    margin-bottom: 10px;
    line-height: normal;
    cursor: pointer;
    position: relative;
    list-style: none;
    &::before {
      content: '\f067';
      background-color: #0071eb;
      font-family: 'Font Awesome 5 Free';
      font-size: 14px;
      font-weight: 900;
      position: absolute;
      right: 0;
      padding: 7px;
      min-width: 27px;
      text-align: center;
      top: 0;
      bottom: 0;
      border-left: 1px solid #fff;
    }
    .personalise-tags {
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      z-index: 9;
      background-color: #f1f1f2;
      display: none;
      span {
        display: block;
        cursor: pointer;
        color: #000;
        padding: 5px 10px;
        &:hover {
          color: #0071eb;
        }
      }
    }
    &.show {
      &::before {
        content: '\f00d';
      }
      .personalise-tags {
        display: block;
      }
    }
  }
}
.message-modal {
  padding: 30px;
  width: 100%;
  max-width: 550px;
  border-radius: 0 !important;
  .close-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    img {
      width: 12px;
    }
  }
  .title {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
    h2 {
      font-weight: 600;
    }
  }
  label {
    display: block;
    color: #0071eb;
    font-size: 18px;
  }
  p {
    font-size: 16px;
    color: #333;
  }
  a {
    color: #0071eb;
  }
  .ui {
    &.button {
      background-color: #1bd2b0;
      color: #fff;
      letter-spacing: 2px;
      padding: 15px 25px;
      font-size: 16px !important;
      &.secondary {
        background-color: #6d6d6d;
        padding: 13px 25px;
        letter-spacing: 2px;
      }
    }
  }
}

.quick-replies {
  .btn {
    text-transform: capitalize;
    font-weight: 400;
  }
  .main-title {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    h3 {
      font-family: 'Gibson Regular';
      font-size: 21px;
      font-weight: 600;
      margin-bottom: 2px;
    }
    span {
      font-size: 18px;
      color: #ff4566;
    }
    .create-template-button {
      line-height: 11px;
      height: 44px;
      background: transparent;
      border: 1px solid #e2e2e2;
    }
  }
  .quick-tips{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
    gap:20px;
    .quick-child{
      border: 1px solid #cccccc;
      display: flex;
      align-items: center;
      padding-right: 20px;
      flex: 1;

    }
  }
  .template-box-wrap {
    padding: 20px;
    background-color: #f7f9fb;
    .search-field {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .ui.input {
        flex: 1;
        width: 100%;
        input {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          width: 100%;
        }
      }
      .search-icon {
        height: 100%;
        padding: 7px 10px 8px;
        border: 1px solid rgba(34, 36, 38, 0.15);
        background: #fff;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
        img {
          width: 17px;
        }
      }
    }
    .template-lists {
      list-style: none;
      padding: 0;
      margin: 0;
      height: 650px;
      overflow-y: auto;
      .labelStyle {
        background-color: #0071eb;
        color: #fff;
        border-radius: 12px;
        padding: 3px 10px;
        line-height: normal;
      }
      li {
        padding: 7px 11px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        &:hover,
        &.active {
          background-color: #eceef0;
        }
        .reply {
          max-width: 160px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 10px;
        }
      }
    }
    .reply-time {
      margin-left: auto;
      flex: 1;
      text-align: right;
    }
  }
  .template-box-right-wrap {
    .width-input {
      width: 58%;
    }
    .form-field {
      margin-bottom: 25px;
      label {
        display: block;
        color: #696969;
        font-size: 16px;
        margin-bottom: 3px;
      }
      p {
        display: block;
        color: #929191;
        font-size: 14px;
        margin-bottom: 5px;
      }
      .ui.selection.dropdown {
        box-shadow: none;
      }
    }
    .button_container {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;

      .save-button {
        background-color: #0071eb;
        color: #fff;
      }
      .duplicate-button {
        color: #989898;
        background-color: #fff;
        border: 1px solid #989898;
      }
      .delete-button {
        color: red;
        background-color: #fff;
        border: 1px solid red;
      }
    }
  }
}
.sms-preview {
  margin-bottom: 20px;
  border: 1px solid #f1f1f2;
  padding: 20px;
  border-radius: 20px;
  min-height: 350px;
  h6 {
    text-transform: uppercase;
    text-align: center;
    word-break: break-word;
  }
  .sms-box {
    background-color: #f1f1f2;
    padding: 15px;
    border-radius: 15px;
    margin-left: 20px;
    min-height: 100px;
    p {
      word-break: break-word;
    }
  }
}
.history-campaign {
  padding-left: 80px;
  .ui.table {
    thead {
      th {
        font-weight: 600;
        color: #999;
      }
    }
  }
}
.setting-tab {
  padding-left: 80px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 80px);
  .message-box {
    max-width: 400px;
    border: 1px solid #ddd;
    padding: 50px 20px;
    margin: 0 auto;
    width: 100%;
    border-radius: 10px;
    h2 {
      font-weight: 900;
    }
  }
}
.no-leads {
  border: 1px dotted #ddd;
  padding: 30px;
  text-align: center;
  max-width: 600px;
  margin:auto;
  border-radius: 10px;
  .icon {
    background-color: #e5e7eb;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    line-height: 46px;
    margin: 0 auto 10px;
  }
  h5 {
    font-weight: 600;
    margin-bottom: 10px;
  }
  p {
    max-width: 70%;
    margin: 0 auto;
  }
}

.date-separate{
  background-color: #efefef;
}