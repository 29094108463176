body {
  margin: 0;
  padding: 0 15px 0 0 !important;
  font-family: 'Gibson Regular' !important;
  overflow-y: auto !important;

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',

    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
}

@font-face {
  font-family: "proxima-nova";
  src: local("proxima-nova"),
    url("./fonts/Proxima-Nova-Font.otf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "acumin-pro";
  src: local("acumin-pro"),
    url("./fonts/Acumin-BdItPro.otf") format("truetype");
  font-weight: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.input-error {
  width: 100%;
}
.input-error .ui.input {
  width: 100%;
}

.verify-modal {
  max-width: 400px;
}

.verify-modal-content {
  display: flex;
  align-items: center;
}

.verify-modal-content .react-tel-input {
  margin-right: 50px;
  width: auto;
}

.verify-modal-content .ui.button {
  color: #ffffff;
  background-color: #0071eb;
  border: 1px solid #0071eb;
  border-radius: 0;
  transition: all 0.3s ease-in-out;
}

.verify-modal-content .react-tel-input .form-control {
  width: 100% !important;
  margin-top: 0px !important;
}

.calling-modal {
  max-width: 600px;
}

.calling-modal .verify-number {
  background: #ffd0d8;
  color: #db0000;
}

.calling-modal .recaptcha p {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    margin-top: 40px;
}

.calling-modal  .verify-number-container {
  margin-bottom: 20px;
}

.calling-modal  .btn-container {
  display: flex;
  justify-content: flex-end;
}
.text-color.react-tel-input input {
  color: #495057;
}
.welcome-modal {
  padding: 20px;
}
.welcome-modal .close-icon {
  text-align: right;
}
.welcome-info {
  text-align: center;
  max-width: 550px;
  margin: 0 auto;
}
.welcome-info p {
  font-size: 16px;
}
.welcome-info p span {
  font-weight: 600;
}
.welcome-img {
  display: flex;
  justify-content: center;
}
.welcome-img img {
  border-radius: 100%;
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin: 10px;
}
.action-btn .ui.button {
  border-radius: 40px;
  color: #fff;
  padding: 15px 10px;
  min-width: 150px;
  margin: 0 5px;
  text-transform: capitalize;
}
.action-btn .ui.button.btn-demo {
  background-color: #1dd2b0;
}
.action-btn .ui.button.btn-demo a {
  color: #fff;
}
.action-btn .ui.button.btn-demo a:hover {
  text-decoration:none;
}
.action-btn .ui.button.btn-ok {
  background-color: #df398b;
}
.action-btn .ui.button.btn-ok:hover {
  color: #fff;
}